import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Slider,
  TextField,
  Paper,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Api from "../../../API/API";
import cookie from "../../../API/cookie";
import { useEffect } from "react";
var g_recv_idx = 800;
var last = 0;
var bluetoothService = null;
const WRITE_UUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
var willBeUploadedDataArr = [];
var defaultValue;

let user_id = cookie.getCookie("userAccount")
  ? cookie.getCookie("userAccount")
  : "";
var api_token = cookie.getCookie("accessToken");

if (user_id) {
  defaultValue = {
    key: api_token,
  };
}
var id;

function ExperimentMachineListPageMiddle(props) {
  const datas = props.data;
  const machine = props.machine;
  const [valueWidth, setValueWidth] = React.useState(0);
  const [valueDuration, setValueDuration] = React.useState(5);
  const [valueAmplitude, setValueAmplitude] = React.useState(0);
  const [valueTime, setValueTime] = React.useState(10);
  const [valueLimit, setValueLimit] = React.useState(0);
  const starttime = props.starttime;
  const [Timer, setTimer] = React.useState(0);
  const [timestatus, settimeStatus] = React.useState(false);
  let endtime;
  const signal_names = ["EEG1", "EEG2", "PPG", "X", "Y", "Z"];
  const [timestatus2, settimeStatus2] = React.useState(false);
  const [starttime2, setstarttime2] = React.useState(undefined);
  const [state, setstate] = React.useState(0);
  const [stateSetting, setstateSetting] = React.useState(0);
  const [stim, setstim] = React.useState('자극 없음');
  const [minimum, setMinimum] = React.useState(0);

  let endtime2;
  setInterval(() => {
    setTimer(Timer + 1);
    // uploadData();
  }, 1000);

  // React.useEffect(() => {
  //   if (starttime2 == undefined) {
  //     if (!timestatus) {
  //       endtime = new Date();
  //       if (((endtime - starttime) / 1000) > 30) {
  //         console.log("down2");
  //         settimeStatus(true);
  //         bluetoothService = machine;
  //         bluetoothService
  //           .getCharacteristic(WRITE_UUID)
  //           .then(function (characteristic) {
  //             var deviceChar = characteristic;
  //             const cmd_intense = "100|0";
  //             var uint8array_intense = new TextEncoder().encode(cmd_intense);
  //             deviceChar
  //               .writeValueWithoutResponse(uint8array_intense);
  //           });
  //       }
  //     }
  //   } else {
  //     if (!timestatus2) {
  //       endtime2 = new Date();
  //       if (((endtime2 - starttime2) / (1000 * 60)) > valueLimit) {
  //         settimeStatus2(true);
  //         console.log("down");
  //         bluetoothService = machine;
  //         bluetoothService
  //           .getCharacteristic(WRITE_UUID)
  //           .then(function (characteristic) {
  //             var deviceChar = characteristic;
  //             const cmd_intense = "910|1";
  //             var uint8array_intense = new TextEncoder().encode(cmd_intense);
  //             deviceChar
  //               .writeValueWithoutResponse(uint8array_intense)
  //               .then(function () {
  //                 const cmd_interval = "100|0";
  //                 var uint8array_interval = new TextEncoder().encode(cmd_interval);
  //                 deviceChar
  //                   .writeValueWithoutResponse(uint8array_interval);
  //               });
  //           });
  //       }
  //     }
  //   }
  // }, [Timer, starttime2])

  const handleWidthSliderChange = (event, newValue) => {
    setValueWidth(newValue);
    setMinimum(Math.round((1/valueDuration * 1000) - (valueWidth/1000))); 
    setValueLimit(minimum);
  };

  const handleDurationSliderChange = (event, newValue) => {
    setValueDuration(newValue);
    setMinimum(Math.round((1/valueDuration * 1000) - (valueWidth/1000)));
    setValueLimit(minimum);
  };

  const handleAmplitudeSliderChange = (event, newValue) => {
    setValueAmplitude(newValue);
  };

  const handleTimeSliderChange = (event, newValue) => {
    if (newValue == 0) {
      newValue = valueDuration;
    }
    setValueTime(newValue);
  };

  const handleLimitSliderChange = (event, newValue) => {
    // console.log(newValue);
    setValueLimit(newValue);
  };

  const handleup = () => {
    AddStimulus(valueAmplitude, valueWidth, valueDuration, valueTime, valueLimit);
    setstate(1);
    setstateSetting(1);
  };

  const handleup2 = () => {
    AddStimulus(0, 0, 0, 0, 7777);
    setstate(2);
    setstateSetting(2);
  };

  const EndStimulus = (time) =>  {
    try{

      const delay = time * 60 * 1000;
      const end_stimulus = () => {
        bluetoothService = machine;
        bluetoothService
        .getCharacteristic(WRITE_UUID)
        .then(function (characteristic){
          var deviceChar = characteristic;
          const end_ = "910|1";
          var uint8array_end = new TextEncoder().encode(end_);
          deviceChar.writeValueWithoutResponse(uint8array_end).then(alert("자극 종료"));
          setstim("자극 없음");
        });
      }

      setTimeout(() => {
        end_stimulus()
      }, delay);
    }
    catch {
      alert("기기 연결이 중단되었습니다. 페이지를 새로고침 해주세요.");
    }
  }

  React.useEffect(() => {
  }, [valueAmplitude, valueWidth, valueDuration, valueTime, valueLimit, state]);

  function AddStimulus(Amplitude, width, duration, Time, limit) {
    try {
      var sti_intensity = width;
      sti_intensity = parseInt(sti_intensity);
      var sti_interval = duration;
      sti_interval = parseInt(sti_interval);
      var sti_height = Amplitude;
      sti_height = parseInt(sti_height);
      var sti_long = Time;
      sti_long = parseInt(sti_long);

      console.log(sti_intensity + " " + sti_interval + " " + sti_height + " "+ sti_long);
      bluetoothService = machine;
      bluetoothService
        .getCharacteristic(WRITE_UUID)
        .then(function (characteristic) {
          var deviceChar = characteristic;
          const cmd_intense = "102|" + sti_intensity;
          var uint8array_intense = new TextEncoder().encode(cmd_intense);
          deviceChar
            .writeValueWithoutResponse(uint8array_intense)
            .then(function () {
              const cmd_interval = "104|" + sti_interval;
              var uint8array_interval = new TextEncoder().encode(cmd_interval);
              deviceChar
                .writeValueWithoutResponse(uint8array_interval)
                .then(function () {
                  const cmd_height = "106|" + sti_height;
                  var uint8array_height = new TextEncoder().encode(cmd_height);
                  deviceChar
                    .writeValueWithoutResponse(uint8array_height)
                    .then(function () {
                      const cmd_intense2 = "910|2";
                      var uint8array_intense2 = new TextEncoder().encode(cmd_intense2);
                      deviceChar.writeValueWithoutResponse(uint8array_intense2)
                        .then(function () {
                          EndStimulus(sti_long);
                        });
                    });
                });
            });
        });
      setstarttime2(new Date());
      if (sti_height !== 0) {
        setstim("자극 전달 중");
        alert("자극 전달 완료");
      }
      else {
        if ( limit != 7777) alert('자극이 0 입니다.');
      }
    }
    catch {
      alert("기기 연결이 중단되었습니다. 페이지를 새로고침 해주세요.");
    }
      
  }

  function widthAmplitude() {
    // if (valueAmplitude == 0) {
    //   return "0mA";
    // } else if (valueAmplitude == 0.1) {
    //   return "0.1mA";
    // }
    // else if (valueAmplitude == 0.2) {
    //   return "0.2mA";
    // }
    // else if (valueAmplitude == 0.3) {
    //   return "0.3mA";
    // }
    // else if (valueAmplitude == 0.4) {
    //   return "0.4mA";
    // }
    // else if (valueAmplitude == 0.5) {
    //   return "0.5mA";
    // }
    // else if (valueAmplitude == 0.6) {
    //   return "0.6mA";
    // }
    // else if (valueAmplitude == 0.7) {
    //   return "0.7mA";
    // }
    // else if (valueAmplitude == 0.8) {
    //   return "0.8mA";
    // }
    // else if (valueAmplitude == 0.9) {
    //   return "0.9mA";
    // }
    // else if (valueAmplitude == 1) {
    //   return "1mA";
    // } else if (valueAmplitude == 2) {
    //   return "2mA";
    // } else if (valueAmplitude == 3) {
    //   return "3mA";
    // } else if (valueAmplitude == 4) {
    //   return "4mA";
    // } else {
    //   return "0mA";;
    // }
    return '' + valueAmplitude
  }

  function widthLimit() {
    // let a = (1/valueDuration * 1000) - (valueWidth/1000);
    // console.log(a);
    // let mini = minimum.toFixed(0);

    // console.log("Minimun: ", mini)
    // console.log("value: ", valueLimit)

    if (valueLimit === minimum) {
      return "Off";
    } else if (valueLimit === minimum + 500) {
      return "500ms";
    } else if (valueLimit === minimum + 1000) {
      return "1000ms";
    } else if (valueLimit === minimum + 1500) {
      return "1500ms";
    } else if (valueLimit === minimum + 2000) {
      return "2000ms";
    } else if (valueLimit === 2500) {
      return "2500ms";
    } else {
      return "Off";
    }
  }

  return (
    <Paper
      style={{ height: "100vh", width: "100%", backgroundColor: "#131313" }}
    >
      <Box style={{ color: "#CCCCCC" }}>
        <Box
          style={{
            padding: "0px 10px 30px 10px",
            borderRadius: 5,
            width: "90%",
            marginRight: "5%",
            marginLeft: "5%",
          }}
        ><Box>
            <h4
              style={{
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "GmarketSansMedium",
              }}
            >
              진폭 명령어 값 (0~2800)
            </h4>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
              <Slider
                value={typeof valueAmplitude === "number" ? valueAmplitude : 0}
                onChange={handleAmplitudeSliderChange}
                aria-labelledby="input-slider"
                min={0}
                max={2800}
                step={20}
              />
              </Grid>
              <Grid item>
                <h3>{widthAmplitude()}</h3>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <h4
              style={{
                marginTop: 0,
                marginBottom: 5,
                fontFamily: "GmarketSansMedium",
              }}
            >
              파형폭 (Phase width, µs)
            </h4>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
              <Slider
                value={typeof valueWidth === "number" ? valueWidth : 0}
                onChange={handleWidthSliderChange}
                aria-labelledby="input-slider"
                min={0}
                max={300}
                step={50}
              />
              </Grid>
              <Grid item>
                <h3>{valueWidth}µs</h3>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <h4
              style={{
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "GmarketSansMedium",
              }}
            >
              주파수 (Frequency, Hz)
            </h4>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
              <Slider
                value={typeof valueDuration === "number" ? valueDuration : 5}
                onChange={handleDurationSliderChange}
                aria-labelledby="input-slider"
                min={5}
                max={50}
                step={5}
              />
              </Grid>
              <Grid item>
                <h3>{valueDuration}ms</h3>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <h4
              style={{
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "GmarketSansMedium",
              }}
            >
              꺼짐 간격 (Off-time, ms)
            </h4>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof valueLimit === "number" ? valueLimit : minimum}
                  onChange={handleLimitSliderChange}
                  aria-labelledby="input-slider"
                  // min={(1/valueDuration * 1000) - (valueWidth/1000)}
                  min={minimum}
                  max={2500}
                  step={500}
                />
              </Grid>
              <Grid item>
                <h3>{widthLimit()}</h3>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <h4
              style={{
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "GmarketSansMedium",
              }}
            >
              타이머 (Timer, min)
            </h4>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <VolumeUp />
              </Grid>
              <Grid item xs>
                <Slider
                  value={typeof valueTime === "number" ? valueTime : 0}
                  onChange={handleTimeSliderChange}
                  aria-labelledby="input-slider"
                  min={10}
                  max={60}
                  step={5}
                />
              </Grid>
              <Grid item>
                <h3>{valueTime}min</h3>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 5 }}>
            <Typography sx={{ fontSize: 25, fontFamily: "GmarketSansMedium" }} color="white" >
              자극 조절 ({stim})
            </Typography>
            <Grid container>
              <Grid item lg={6} md={6} sm={6} xs={1}>
                <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup}>
                  <CardContent>
                    <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center"}} color="white" >
                      자극 사용
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={1}>
                <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup2}>
                  <CardContent>
                    <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center"}} color="white" >
                      자극 중지
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default ExperimentMachineListPageMiddle;
